<template>
  <Container class="!max-w-[1362px]">
    <div class="h-[1000px]"></div>
    <SubTitle class="mb-3 block text-center text-blue lg:mb-14">
      {{ data?.subtitle }}
    </SubTitle>
    <div
      class="hidden flex-row xl:flex"
      :class="[data?.cards && data?.cards?.length <= 2 ? 'justify-center' : 'justify-between']"
    >
      <template v-for="(item, index) in data?.cards" :key="index">
        <div v-if="index !== 0" class="mx-4 h-[170px] w-0.5 bg-[#CAF7FF]"></div>
        <div class="relative flex basis-full flex-col items-center text-center xl:max-w-[420px]">
          <Pie :percent="Math.random() * 100" />
          <HeaderLine />
          <div
            v-if="item?.copy"
            class="mt-7 font-sans text-base leading-snug text-black lg:mt-12 lg:text-lg"
            v-html="item?.copy"
          ></div>
        </div>
      </template>
    </div>
    <div class="relative xl:hidden">
      <ClientOnly>
        <Swiper
          :modules="[Controller, Navigation, Pagination]"
          :controller="{ control: controlledSwiper }"
          :slides-per-view="1"
          :space-between="30"
          :centered-slides="true"
          :loop="false"
          :observer="true"
          :pagination="{
            dynamicBullets: true,
          }"
          @swiper="setSwiper"
        >
          <SwiperSlide v-for="(item, index) in data?.cards" :key="index">
            <div
              class="relative flex basis-full flex-col items-center text-center xl:max-w-[420px]"
            >
              <Pie :percent="Math.random() * 100" />
              <HeaderLine />
              <div
                v-if="item?.copy"
                class="mt-7 font-sans text-base leading-snug text-black lg:mt-12 lg:text-lg"
                v-html="item?.copy"
              ></div>
            </div>
          </SwiperSlide>
        </Swiper>
        <SliderNavigation :slider="controlledSwiper" />
      </ClientOnly>
    </div>
    <div v-if="data.link" class="mt-14 flex justify-center lg:mt-20">
      <Link
        type="outline"
        :title="data.link.title"
        :link="data.link.url"
        :external="data?.link?.type === 'ext'"
        class="mx-4 w-full lg:mx-auto lg:w-auto"
      />
    </div>
  </Container>
</template>

<script setup lang="ts">
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Swiper as SwiperType } from 'swiper'
import { Controller, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { ref } from 'vue'
import type { Ref } from 'vue'

import SubTitle from '../../atoms/SubTitle.vue'
import SliderNavigation from '../../atoms/SliderNavigation.vue'
import HeaderLine from '../../atoms/Icons/HeaderLine.vue'
import Container from '../../atoms/Container.vue'
import Pie from '../../atoms/Pie.vue'
import Link from '../../atoms/Link.vue'

const controlledSwiper: Ref<SwiperType | undefined> = ref()

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
}

const data = {
  type: 'paragraph--content_facts',
  id: 'deacfdb0-ea86-4d08-8a27-0c50525dbb44',
  subtitle: 'Pellentesque vel lacus viverra pharetra',
  cards: [
    {
      title: 'Pellentesque vel lacus viverra pharetra #1',
      copy: '<p>Sed quis elementum tortor, at consectetur magna. Fusce sit amet odio sed ligula faucibus pellentesque. Nullam ultrices, ligula eu pellentesque blandit, est magna facilisis est, vitae cursus nisl arcu eu justo. Ut suscipit ex at sem rhoncus, ut pretium mauris tempor. Proin sit amet nulla aliquam, posuere ex id, eleifend nunc.</p>\n',
    },
    {
      title: 'Pellentesque vel lacus viverra pharetra #2',
      copy: '<p>Sed quis elementum tortor, at consectetur magna. Fusce sit amet odio sed ligula faucibus pellentesque. Nullam ultrices, ligula eu pellentesque blandit, est magna facilisis est, vitae cursus nisl arcu eu justo. Ut suscipit ex at sem rhoncus, ut pretium mauris tempor. Proin sit amet nulla aliquam, posuere ex id, eleifend nunc.</p>\n',
    },
    {
      title: 'Pellentesque vel lacus viverra pharetra #3',
      copy: '<p>Sed quis elementum tortor, at consectetur magna. Fusce sit amet odio sed ligula faucibus pellentesque. Nullam ultrices, ligula eu pellentesque blandit, est magna facilisis est, vitae cursus nisl arcu eu justo. Ut suscipit ex at sem rhoncus, ut pretium mauris tempor. Proin sit amet nulla aliquam, posuere ex id, eleifend nunc.</p>\n',
    },
  ],
  link: { type: 'ext', title: 'Mauris molestie', url: 'https://www.lipsum.com/' },
}
</script>
